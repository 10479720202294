<template>
  <div>
    <div class="row">
      <div class="col-xl-8 pl-0">
        <!-- <img src="media/images/adidas.svg" /> -->
        <img class="logo" :src="client_detail.logo" />
        <h1 class="ml-4 main-header">
          <b>{{ client_detail.name }} Payout</b>
        </h1>

        <v-btn
          class="mx-1 ml-4 mr-4 mdi-plus-bg"
          @click="createPayout"
          fab
          dark
          color="gainsboro"
        >
          <v-icon class="mdi-plus-content">mdi-plus</v-icon>
        </v-btn>
      </div>

      <div class="col-xl-4">
        <div>
          <!-- <div style="float: right; display: inline-flex">
            <input class="form-control" type="month" :value="date" />
          </div> -->
          <!-- <b-form-group
            id="fieldset-start-date"
            label-cols-sm="4"
            label-cols-lg="6"
            content-cols-sm
            content-cols-lg="10"
            label-for="date"
          >
            <b-form-datepicker
              id="date"
              v-model="date"
              @context="onSelectStartDate"
              placeholder="1/10/2021"
              locale="en"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
            ></b-form-datepicker>
          </b-form-group> -->

          <div style="float: right">
            <label for="check-button">
              <h6 style="padding-top: 3px">
                <b>Switch to Calendar view</b>
              </h6>
            </label>
            <b-form-checkbox
              name="check-button"
              v-model="show_calendar"
              size="lg"
              switch
              class="switch-to-calendar-button"
            >
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </div>

    <!-- ACTIVE PAYOUT RULES -->
    <div v-if="!show_calendar">
      <h2 style="font-weight: bold; margin-bottom: 20px">
        Active Payout Rules
      </h2>
      <div class="row mb-20">
        <div class="col-xl-12">
          <div
            class="
              card card-custom card-stretch
              gutter-b
              card-shadowless
              my-class-shadow
            "
          >
            <div class="card-body py-0">
              <div style="width: 100%">
                <b-table
                  class="ag-theme-material"
                  sticky-header
                  style="width: 100%; margin-top: 20px"
                  hover
                  :fields="activePayoutFields"
                  :items="activePayouts"
                >
                  <!-- A custom formatted header cell for select all checkbox -->
                  <template #head(index)="">
                    <b-form-checkbox
                      @change="selectAllActivePayout"
                      class="custom-control custom-checkbox checkbox-xl"
                      :checked="
                        selectedActivePayout.length === activePayouts.length
                      "
                    ></b-form-checkbox>
                  </template>

                  <!-- checkbox slot for each row -->
                  <template v-slot:cell(index)="slot">
                    <b-form-checkbox
                      name="selected-items"
                      class="custom-control custom-checkbox checkbox-xl"
                      v-model="selectedActivePayout"
                      :value="slot.item"
                    >
                    </b-form-checkbox>
                  </template>

                  <template v-slot:cell(region)="slot">
                    <span
                      v-if="
                        slot.item.regions.length === 4 ||
                          slot.item.regions.length === 0
                      "
                    >
                      ALL
                    </span>
                    <span v-else>
                      {{ slot.item.region }}
                    </span>
                  </template>
                  <!-- action slot for each row -->
                  <template v-slot:cell(action)="slot">
                    <b-row>
                      <b-col
                        cols="auto"
                        style="text-align: center"
                        @click="editPayout(slot.item._id)"
                      >
                        <b-avatar role="button" src="media/images/edit.png" />
                        <br />
                        <b>EDIT</b>
                      </b-col>
                      <b-col
                        style="text-align: center"
                        v-b-modal.delete-payout
                        @click="
                          deleteAllConfirmation('active');
                          payoutToDel = slot.item;
                        "
                        cols="auto"
                      >
                        <b-avatar role="button" src="media/images/cancel.png" />
                        <br />
                        <b>CANCEL</b>
                      </b-col>
                      <b-col
                        cols="auto"
                        style="text-align: center"
                        @click="copyPayout(slot.item)"
                      >
                        <b-avatar role="button" src="media/images/copy.png" />
                        <br />
                        <b>COPY</b>
                      </b-col>
                    </b-row>
                  </template>
                </b-table>
                <div
                  class="no-records"
                  v-if="!activePayouts || !activePayouts.length"
                >
                  No Records to show
                </div>
                <b-row>
                  <b-col>
                    <b-pagination
                      v-model="active_current_page"
                      pills
                      :total-rows="active_rows"
                      :per-page="active_per_page"
                      class="b-pagiantion-button"
                    ></b-pagination>
                  </b-col>
                  <b-col style="margin-right: 30px">
                    <b-button
                      class="deleteAll-btn"
                      v-b-modal.deleteAll-payout
                      :disabled="!selectedActivePayout.length"
                      @click="deleteAllConfirmation('active')"
                      cols="auto"
                      >Delete All</b-button
                    >
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- UPCOMING PAYOUT RULES -->
      <h2 style="font-weight: bold; margin-bottom: 20px">
        Upcoming Payout Rules
      </h2>
      <div class="row mb-20">
        <div class="col-xl-12">
          <div
            class="
              card card-custom card-stretch
              gutter-b
              card-shadowless
              my-class-shadow
            "
          >
            <div class="card-body py-0">
              <div style="width: 100%">
                <b-table
                  class="ag-theme-material"
                  sticky-header
                  style="width: 100%; margin-top: 20px"
                  hover
                  :fields="activePayoutFields"
                  :items="upcomingPayouts"
                >
                  <!-- A custom formatted header cell for select all checkbox -->
                  <template #head(index)="">
                    <b-form-checkbox
                      @change="selectAllUpcomingPayout"
                      class="custom-control custom-checkbox checkbox-xl"
                      :checked="
                        selectedUpcomingPayout.length === upcomingPayouts.length
                      "
                    ></b-form-checkbox>
                  </template>

                  <!-- checkbox slot for each row -->
                  <template v-slot:cell(index)="slot">
                    <b-form-checkbox
                      name="selected-items"
                      class="custom-control custom-checkbox checkbox-xl"
                      v-model="selectedUpcomingPayout"
                      :value="slot.item"
                    >
                    </b-form-checkbox>
                  </template>

                  <template v-slot:cell(region)="slot">
                    <span
                      v-if="
                        slot.item.regions.length === 4 ||
                          slot.item.regions.length === 0
                      "
                    >
                      ALL
                    </span>
                    <span v-else>
                      {{ slot.item.region }}
                    </span>
                  </template>

                  <!-- action slot for each row -->
                  <template v-slot:cell(action)="slot">
                    <b-row>
                      <b-col
                        cols="auto"
                        style="text-align: center"
                        @click="editPayout(slot.item._id)"
                      >
                        <b-avatar role="button" src="media/images/edit.png" />
                        <br />
                        <b>EDIT</b>
                      </b-col>
                      <b-col
                        style="text-align: center"
                        v-b-modal.delete-payout
                        @click="
                          deleteAllConfirmation('upcoming');
                          payoutToDel = slot.item;
                        "
                        cols="auto"
                      >
                        <b-avatar role="button" src="media/images/cancel.png" />
                        <br />
                        <b>CANCEL</b>
                      </b-col>
                      <b-col
                        cols="auto"
                        style="text-align: center"
                        @click="copyPayout(slot.item)"
                      >
                        <b-avatar role="button" src="media/images/copy.png" />
                        <br />
                        <b>COPY</b>
                      </b-col>
                    </b-row>
                  </template>
                </b-table>
                <div
                  class="no-records"
                  v-if="!upcomingPayouts || !upcomingPayouts.length"
                >
                  No Records to show
                </div>

                <b-row>
                  <b-col>
                    <b-pagination
                      v-model="upcoming_current_page"
                      pills
                      :total-rows="upcoming_rows"
                      :per-page="upcoming_per_page"
                      class="b-pagiantion-button"
                    ></b-pagination>
                  </b-col>
                  <b-col style="margin-right: 30px">
                    <b-button
                      class="deleteAll-btn"
                      v-b-modal.deleteAll-payout
                      :disabled="!selectedUpcomingPayout.length"
                      @click="deleteAllConfirmation('upcoming')"
                      cols="auto"
                      >Delete All</b-button
                    >
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- PAST PAYOUT RULES -->
      <h2 style="font-weight: bold; margin-bottom: 20px">Past Payout Rules</h2>
      <div class="row mb-20">
        <div class="col-xl-12">
          <div
            class="
              card card-custom card-stretch
              gutter-b
              card-shadowless
              my-class-shadow
            "
          >
            <div class="card-body py-0">
              <div style="width: 100%">
                <b-table
                  sticky-header
                  style="width: 100%; margin-top: 20px"
                  hover
                  :fields="activePayoutFields"
                  :items="pastPayouts"
                >
                  <!-- A custom formatted header cell for select all checkbox -->
                  <template #head(index)="">
                    <b-form-checkbox
                      @change="selectAllPastPayout"
                      class="custom-control custom-checkbox checkbox-xl"
                      :checked="
                        selectedPastPayout.length === pastPayouts.length
                      "
                    ></b-form-checkbox>
                  </template>

                  <!-- checkbox slot for each row -->
                  <template v-slot:cell(index)="slot">
                    <b-form-checkbox
                      name="selected-items"
                      class="custom-control custom-checkbox checkbox-xl"
                      v-model="selectedPastPayout"
                      :value="slot.item"
                    >
                    </b-form-checkbox>
                  </template>
                  <template v-slot:cell(region)="slot">
                    <span
                      v-if="
                        slot.item.regions.length === 4 ||
                          slot.item.regions.length === 0
                      "
                    >
                      ALL
                    </span>
                    <span v-else>
                      {{ slot.item.region }}
                    </span>
                  </template>
                  <!-- action slot for each row -->
                  <template v-slot:cell(action)="slot">
                    <b-row>
                      <b-col
                        cols="auto"
                        style="text-align: center"
                        @click="editPayout(slot.item._id)"
                      >
                        <b-avatar role="button" src="media/images/edit.png" />
                        <br />
                        <b>EDIT</b>
                      </b-col>
                      <b-col
                        style="text-align: center"
                        v-b-modal.delete-payout
                        @click="
                          deleteAllConfirmation('past');
                          payoutToDel = slot.item;
                        "
                        cols="auto"
                      >
                        <b-avatar role="button" src="media/images/cancel.png" />
                        <br />
                        <b>CANCEL</b>
                      </b-col>
                      <b-col
                        style="text-align: center"
                        cols="auto"
                        @click="copyPayout(slot.item)"
                      >
                        <b-avatar role="button" src="media/images/copy.png" />
                        <br />
                        <b>COPY</b>
                      </b-col>
                    </b-row>
                  </template>
                </b-table>
                <div
                  class="no-records"
                  v-if="!pastPayouts || !pastPayouts.length"
                >
                  No Records to show
                </div>

                <b-row>
                  <b-col>
                    <b-pagination
                      v-model="past_current_page"
                      pills
                      :total-rows="past_rows"
                      :per-page="past_per_page"
                      class="b-pagiantion-button"
                    ></b-pagination>
                  </b-col>
                  <b-col style="margin-right: 30px">
                    <b-button
                      class="deleteAll-btn"
                      v-b-modal.deleteAll-payout
                      :disabled="!selectedPastPayout.length"
                      @click="deleteAllConfirmation('past')"
                      cols="auto"
                      >Delete All</b-button
                    >
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <payout-calendar v-if="show_calendar" :clientId="$route.params.id">
    </payout-calendar>
    <b-modal
      id="delete-payout"
      :title="'Confirmation for Payout ' + payoutToDel.payout_name"
      ok-variant="danger"
      ok-title="Remove"
      @ok="del"
      size="sm"
      centered
    >
      <h5>Are you sure you want to delete this payout?</h5>
    </b-modal>
    <b-modal
      id="deleteAll-payout"
      :title="'Confirmation for Payout '"
      ok-variant="danger"
      ok-title="Remove"
      @ok="delAll"
      size="sm"
      centered
    >
      <h5>Are you sure you want to delete all following payouts?</h5>
      <ul style="margin-left: 20px" v-if="findPayout == 'active'">
        <li v-for="(item, i) in selectedActivePayout" v-bind:key="i">
          {{ item.payout_name }}
        </li>
      </ul>
      <ul style="margin-left: 20px" v-if="findPayout == 'upcoming'">
        <li v-for="(item, i) in selectedUpcomingPayout" v-bind:key="i">
          {{ item.payout_name }}
        </li>
      </ul>
      <ul style="margin-left: 20px" v-if="findPayout == 'past'">
        <li v-for="(item, i) in selectedPastPayout" v-bind:key="i">
          {{ item.payout_name }}
        </li>
      </ul>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Dropdown7 from "@/view/content/dropdown/Dropdown7.vue";
import ApiService from "../../../core/services/api.service";
import PayoutCalendar from "./payout-calendar.vue";
export default {
  components: {
    Dropdown7,
    PayoutCalendar,
  },
  computed: {
    ...mapGetters(["ClientGetters"]),
    client_detail() {
      return this.$store.getters.ClientGetters;
    },
  },
  data() {
    return {
      date: new Date().toISOString().substring(0, 7),
      pagination: [],
      searchQuery: "",
      maxPageNumbers: 10,
      selectedActivePayout: [],
      selectedPastPayout: [],
      selectedUpcomingPayout: [],
      currentRowIndex: -1,
      active_current_page: 1,
      active_per_page: 5,
      active_rows: 0,
      past_current_page: 1,
      past_per_page: 5,
      past_rows: 0,
      upcoming_current_page: 1,
      upcoming_per_page: 5,
      upcoming_rows: 0,
      activePayoutFields: [
        {
          key: "index",
          label: "",
        },
        "payout_name",
        "payout_type",
        "channels",
        "region",
        "rules",
        "dates",
        "action",
      ],
      activePayouts: [],
      upcomingPayouts: [],
      pastPayouts: [],

      payoutPayload: {},

      payoutToDel: {},
      findPayout: "",
      show_calendar: false,
    };
  },

  mounted() {
    // if user refresh this page directly so we've to hit api again to get client details.
    if (!this.client_detail || !this.client_detail.length) {
      this.$store.dispatch("CLIENT_ACTION", this.$route.params.id);
    }
    this.payoutPayload = {
      client_id: this.$route.params.id,
      // month: this.date.split("-")[1],
    };
    localStorage.removeItem("copyPayout"), this.getActivePayouts();
    this.getUpcomingPayouts();
    this.getPastPayouts();
  },
  methods: {
    selectAllActivePayout() {
      if (this.selectedActivePayout.length === this.activePayouts.length) {
        this.selectedActivePayout = [];
      } else {
        this.selectedActivePayout = this.activePayouts.slice();
      }
    },
    selectAllUpcomingPayout() {
      if (this.selectedUpcomingPayout.length === this.upcomingPayouts.length) {
        this.selectedUpcomingPayout = [];
      } else {
        this.selectedUpcomingPayout = this.upcomingPayouts.slice();
      }
    },
    selectAllPastPayout() {
      if (this.selectedPastPayout.length === this.pastPayouts.length) {
        this.selectedPastPayout = [];
      } else {
        this.selectedPastPayout = this.pastPayouts.slice();
      }
    },
    createPayout() {
      this.$router.push({
        name: "create-payout",
        params: { id: this.$route.params.id },
      });
    },
    editPayout(payoutId) {
      this.$router.push({
        name: "edit-payout",
        params: { id: payoutId },
        query: { name: this.client_detail.name },
      });
    },
    copyPayout(payout) {
      // this.payload = payout;
      let payload = {};
      payload = payout._id;
      localStorage.setItem("copyPayout", payload),
        this.$router.push({
          name: "create-payout",
          params: { id: this.$route.params.id },
        });
    },
    formatRules(payload) {
      let res = [];
      if (payload.is_channel) {
        res.push("Channels");
      }
      if (payload.is_region) {
        res.push("Regions");
      }
      if (payload.is_coupon_series) {
        res.push(" Coupons");
      }
      if (payload.is_revenue) {
        res.push(" RevSlab");
      }
      return res.join(",");
    },
    async getActivePayouts() {
      try {
        let response = await ApiService.post(
          `/payout/active-payouts?page=${this.active_current_page}`,
          {
            ...this.payoutPayload,
            month: this.date.split("-")[1],
          }
        );
        console.log("active res", response.data.data);
        this.active_rows =
          response.data.data.pagination.length &&
          response.data.data.pagination[0].total;
        // this.active_current_page = res.data.data.current_page;
        // this.active_per_page = res.data.data.per_page;
        this.activePayouts = response.data.data.data.map((res) => {
          let options = { day: "numeric", month: "short", year: "numeric" };
          let from_date = new Date(res.from_date);
          let to_date = res.is_end_date ? new Date(res.to_date) : null;
          from_date = from_date.toLocaleDateString("en-US", options);
          to_date = res.is_end_date
            ? to_date.toLocaleDateString("en-US", options)
            : "Current";
          console.log("resssss", res.regions);
          var region = res.regions;
          // if (region.length > 0) {
          //   console.log("empty");
          // } else {
          //   region = ["ALL"];
          //   console.log("tetetet");
          // }
          return {
            ...res,
            dates: `${from_date} - ${to_date}`,
            region: region.join(),
            rules: this.formatRules(res),
            payout_name: res.name ? res.name : res.payout_name,
            payout_type: res.default_payout_type,
            channels:
              res.channels && Array.isArray(res.channels)
                ? res.channels.join()
                : res.channels,
          };
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getUpcomingPayouts() {
      try {
        let response = await ApiService.post(
          `/payout/upcoming-payouts?page=${this.upcoming_current_page}`,
          {
            ...this.payoutPayload,
            month: this.date.split("-")[1],
          }
        );

        this.upcomingPayouts = response.data.data.data.map((res) => {
          let options = { day: "numeric", month: "short", year: "numeric" };
          let from_date = new Date(res.from_date);
          let to_date = res.is_end_date ? new Date(res.to_date) : null;
          from_date = from_date.toLocaleDateString("en-US", options);
          to_date = res.is_end_date
            ? to_date.toLocaleDateString("en-US", options)
            : "Current";
          var region = res.regions;
          // if (region.length > 0) {
          //   console.log("empty");
          // } else {
          //   region = ["ALL"];
          //   console.log("tetetet");
          // }
          return {
            ...res,
            dates: `${from_date} - ${to_date}`,
            region: region.join(),
            rules: this.formatRules(res),
            payout_name: res.name ? res.name : res.payout_name,
            payout_type: res.default_payout_type,
            channels:
              res.channels && Array.isArray(res.channels)
                ? res.channels.join()
                : res.channels,
          };
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getPastPayouts() {
      try {
        let response = await ApiService.post(
          `/payout/past-payouts?page=${this.past_current_page}`,
          {
            ...this.payoutPayload,
            month: this.date.split("-")[1],
          }
        );
        this.pastPayouts = response.data.data.data.map((res) => {
          let options = { day: "numeric", month: "short", year: "numeric" };
          let from_date = new Date(res.from_date);
          let to_date = res.is_end_date ? new Date(res.to_date) : null;
          from_date = from_date.toLocaleDateString("en-US", options);
          to_date = res.is_end_date
            ? to_date.toLocaleDateString("en-US", options)
            : "Current";
          var region = res.regions;
          // if (region.length > 0) {
          //   console.log("empty");
          // } else {
          //   region = ["ALL"];
          //   console.log("tetetet");
          // }

          return {
            ...res,
            dates: `${from_date} - ${to_date}`,
            region: region.join(),
            payout_name: res.name ? res.name : res.payout_name,
            rules: this.formatRules(res),
            payout_type: res.default_payout_type,
            channels:
              res.channels && Array.isArray(res.channels)
                ? res.channels.join()
                : res.channels,
          };
        });
      } catch (error) {
        console.log(error);
      }
    },
    async del() {
      if (!this.payoutToDel) {
        return;
      }
      try {
        await ApiService.post("/payout/delete-payout", {
          payout_ids: [this.payoutToDel._id],
        });
        this.payoutToDel = {};
        if (this.findPayout == "active") {
          this.getActivePayouts();
        }
        if (this.findPayout == "upcoming") {
          this.getUpcomingPayouts();
        }
        if (this.findPayout == "past") {
          this.getPastPayouts();
        }
      } catch (err) {
        console.log(err);
      }
    },
    async delAll() {
      if (
        !this.selectedActivePayout &&
        !this.selectedUpcomingPayout &&
        !this.selectedPastPayout
      ) {
        return;
      }
      let allDeletePayout = [];
      if (this.findPayout == "active") {
        this.selectedActivePayout.map((item, index) => {
          allDeletePayout.push(item._id);
        });
      }
      if (this.findPayout == "upcoming") {
        this.selectedUpcomingPayout.map((item, index) => {
          allDeletePayout.push(item._id);
        });
      }
      if (this.findPayout == "past") {
        this.selectedPastPayout.map((item, index) => {
          allDeletePayout.push(item._id);
        });
      }
      try {
        await ApiService.post("/payout/delete-payout", {
          payout_ids: allDeletePayout,
        });

        allDeletePayout = [];
        this.selectedActivePayout = [];
        this.selectedPastPayout = [];
        this.selectedUpcomingPayout = [];
        if (this.findPayout == "active") {
          this.getActivePayouts();
        }
        if (this.findPayout == "upcoming") {
          this.getUpcomingPayouts();
        }
        if (this.findPayout == "past") {
          this.getPastPayouts();
        }
      } catch (err) {
        console.log(err);
      }
    },
    deleteAllConfirmation(event) {
      this.findPayout = event;
    },
    onSelectStartDate(ctx) {
      this.date = ctx.activeYMD;
    },
  },
  watch: {
    date: {
      handler: function() {
        this.payoutPayload = {
          client_id: this.$route.params.id,
          month: this.date.split("-")[1],
        };
        // this.getActivePayouts();
        // this.getUpcomingPayouts();
        // this.getPastPayouts();
      },
    },
    active_current_page: {
      handler: function() {
        // if (this.totalRecords <= 0) {
        //   return;
        // }
        this.getActivePayouts();
      },
    },
    past_current_page: {
      handler: function() {
        // if (this.totalRecords <= 0) {
        //   return;
        // }
        this.getPastPayouts();
      },
    },
    upcoming_current_page: {
      handler: function() {
        // if (this.totalRecords <= 0) {
        //   return;
        // }
        this.getUpcomingPayouts();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .table {
  height: calc(100% - 25px);
  font-size: 12px;
  th,
  td {
    vertical-align: middle;
    padding: 10px;
  }
  thead {
    color: #000;
    th {
      text-transform: uppercase;
      padding-bottom: 15px;
    }
  }
  #checkbox-1::after {
    width: 25px;
    height: 22px;
    background: #00a4f3 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
  }

  .custom-control-input:checked ~ .custom-control-label::before,
  .custom-control-input:checked ~ .custom-control-label::after {
    box-shadow: 0 0 0 2px rgba(32, 33, 36, 0.122) !important;
    border: none !important;
  }

  .checkbox-xl .custom-control-label::before,
  .checkbox-xl .custom-control-label::after {
    width: 18px;
    height: 18px;
    border-radius: 2px;
    opacity: 1;
    font-weight: 400;
    border: 2px solid #0000008a;
  }
  .b-avatar {
    height: 25px;
    width: 25px;
  }
}
.logo {
  height: 85px;
  width: 80px;
  border-radius: 20px;
  border: 2px solid white;
  box-shadow: gainsboro 0px 0px 5px;
}
.card.card-custom.card-stretch.gutter-b {
  -webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.card.card-custom > .card-body {
  padding: 2rem 1em 2rem 2.29rem;
}
.card {
  border-radius: 20px;
}

/* Scroll bar style */
.b-table-sticky-header {
  overflow-y: auto;
  max-height: 500px;
  &::-webkit-scrollbar {
    width: 5px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #fff0d9;
    outline: 1px solid #fff0d9;
  }
}
.no-records {
  height: 400px;
  text-align: center;
  padding: 50px;
}
.deleteAll-btn {
  background: rgb(243, 14, 83);
  float: right !important;
  color: white;
  width: 120px !important;
  margin-bottom: 20px !important;
}
</style>
